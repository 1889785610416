.student-selection {
  transition: 0.2s ease-in-out;
  background-color: rgb(195, 205, 207);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #ccc; 
  padding: 15px; 
  border-radius: 8px; 
  text-align: center;
  width: 220px;
  img {
    height: 100px;
    width: 100px;
  }

  .no-pic {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px black solid;
    border-radius: 100%;
  }

  .contact-information {
    width: 100%;
  }

  .card-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  
  &.selected {
    background-color: #414bb2;
    color: rgb(251, 245, 245);
    transform: scale(1.1);
  }
}

.contact-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  padding: 0 10px;
  max-width: 100%;
  gap: 20px;
  .add-contact-btn { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    width: 220px;
    height: 220px;
    margin-top: 5px;
  }
}
