.main-container-app {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin: 20px 60px;
  place-content: start;

  .app-type {
    padding: 15px;
    transition: 0.3s ease-in-out;
    border-radius: 30px !important;
    cursor: pointer;
    flex-direction: column;
    min-height: 150px;
    &:hover {
      background-color: #414bb2;
      color: white;
    }
  
    .app-title {
      font-size: 20px;
      font-weight: 600;
    }
  
    &.selected {
      background-color: #414bb2;
      color: white;
      transform: scale(1.1);
    }
  }
  
  .variant-info {
    padding: 8px;
    border-radius: 4px;
    margin-top: 1rem; /* Ensure space between content and variant-info */
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .main-container-app {
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 10px 20px;
  }
}
