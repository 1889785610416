.fixed-buttons-container {
  position: fixed;
  z-index: 1000; 
  background: white; 
  padding: 1rem;
  width: calc(100% - 335px);
  top: 0;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.step-indicator {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}
.step-indicator span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  margin: 0 5px;
}

.step-indicator .active {
  background-color: grey;
}

.step-indicator .completed {
  background-color: green;
}

.form-nav {
  display: flex;
  justify-content: space-between;
}

.form-navigation {
  display: flex;
  justify-content: flex-end;
}