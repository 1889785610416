.scrollbar {
  ::-webkit-scrollbar {
    width: 8px;
  }
  --kt-scrollbar-color: gray;
  --kt-scrollbar-hover-color: gray;
  ::-webkit-scrollbar-thumb {
    background-color: gray;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: darkgray;
  }

  /* For Firefox */
  scrollbar-color: gray;
}
