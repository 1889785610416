//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base";
@import "layout/layout";

.splash-screen2 {
   position: absolute;
   z-index: 1000;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   font-family: Helvetica, 'sans-serif';
   background-color: rgba(247, 243, 243, 0.585) ;
   height: 100%;
   width: 100%;
   color: #5E6278;
   line-height: 1;
   font-size: 14px;
   font-weight: 400;
}

// #loading-message {
//    bottom: 0;
//    color: #393939;
//    display: none;
//    font-size: 2.5em;
//    left: 0;
//    position: fixed;
//    right: 0;
//    text-align: center;
//    top: 15%;
//    z-index: 250;
//    text-shadow: 0px 1px 12px rgba(0, 0, 0, 0.57);
//    font-weight: bold;
//    line-height: 2.1em;
// }
.display-space-between{
   display: flex;
   justify-content: space-between;
}
.payment-method-nav-tabs{
   .nav-item{
      .active{
         border-bottom: 2px solid #0096ea !important;
         color: #0096ea;
         font-weight: 500;
      }
   }
}
.nav-line-tabs{
   border-bottom-color: #b0b0b0;
}