.main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.focus-area-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 20px;
}

@media screen and (min-width: 781px) and (max-width: 1280px) {
  .focus-area-container {
    grid-template-columns: repeat(4, 1fr); /* Larger medium screens */
  }
}

@media screen and (min-width: 540px) and (max-width: 780px) {
  .focus-area-container {
    grid-template-columns: repeat(3, 1fr); /* Medium screens */
  }
}

@media screen and (max-width: 540px) {
  .focus-area-container {
    grid-template-columns: repeat(2, 1fr); /* Small screens */
  }
}

@media screen and (max-width: 361px) {
  .focus-area-container {
    grid-template-columns: repeat(1, 1fr); /* Small screens */
  }
}

.programs-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .programs-list {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in-out;
    background-color: rgb(195, 205, 207);
    border-radius: 10px;
    min-height: 70px;
    padding: 0 5px;
    cursor: pointer;
  }
}

.programs-list:hover {
  background-color: #414bb2;
  color: white
}

.programs-list {
  &.selected {
    background-color: #414bb2;
    color: white;
  }
}

.text {
  font-size: 16px;
}