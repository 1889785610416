.btn-school {
  display: flex;
  width: calc(100% - 265px);
  margin: 3rem auto 0 !important;
  padding: 3rem 0;
  justify-content: space-evenly;
} 

.btn-default, .btn-active {
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.btn-default {
  background: #e6e6e6;
  color: black;
}

.btn-active {
  background: #414bb2;
  color: white;
}