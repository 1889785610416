.tooltip-btn {
    position: relative;
  }
  
  .tooltip-text {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip-btn:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  .default {
    transition: 0.2s ease-in-out !important;
    &:hover {
      background: #414bb2 !important;
      color: white !important;
    }
  }