.incomplete {
	background: rgba(233, 100, 98, 0.5) !important;
	cursor: pointer;
	text-decoration: underline;
	transition: all 0.2s ease-in-out;
	&:hover {
		background: #f16663 !important;
	}
}

.submitted { 
	background: #f5c310 !important;
}

.completed {
	background: #98da88 !important;
}