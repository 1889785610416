.student-type-container {
  display: flex;
  grid-template-columns: repeat(2, 600px);
  width: 100%;
  padding: 1rem;
  gap: 5rem;
}

.student-card {
  flex: 1;
  padding: 1.5rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  transition: 0.2s ease-in-out;
  background-color: rgb(195, 205, 207);
  &.active {
    background-color: #414bb2;
    color: white;
    transform: scale(1.05);
  }

  h2 {
    font-size: 2rem;
  }

  .pricing {
    font-weight: 800;
    padding-left: 5px;
  }

  .perks-label {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 800;
  }

  .perks-text {
    font-size: 16px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 768px) {
  .student-type-container {
    flex-direction: column;
    align-items: center;
  }

  .student-card {
    width: 100%;
    margin-bottom: 1rem;
  }
}
