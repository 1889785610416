.my-contact-list { 
  display: grid;
  grid-template-columns: repeat(5, 250px);
  gap: 16px;
} 
  
.contact-card { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #ccc; 
  padding: 15px; 
  border-radius: 8px; 
  text-align: center;
  width: 250px; 
  img {
    height: 100px;
    width: 100px;
  }

  .no-pic {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px black solid;
    border-radius: 100%;
  }

  .contact-information {
    width: 100%;
  }

  .card-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
}

.add-contact-btn {
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .fa-plus {
        font-size: 150px;
        color: black;
        margin-top: 15px;
    }

    button {
        margin-top: 10px;
    }
}
