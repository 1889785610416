.main-container {

  .header-program {
    position: fixed;
    display: flex;
    z-index: 1000;
    padding-top: 20px;
    top: 20px;
    background: white;
    width: calc(100% - 325px);
    margin-bottom: 3rem !important;
    .header-container {
      width: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .btn-school {
      display: flex;
      width: calc(100% - 265px);
      margin: 3rem auto 0 !important;
      padding: 3rem 0;
      justify-content: space-evenly;
    }
  }

  .button-ctn {
    width: 160px;
    button {
      width: 100%;
      font-size: 20px;
    }
  }

  .campus-container {
    .hs-container,
    .ms-container {
      display: grid;
      gap: 15px;
      margin: 20px 0;
      grid-template-columns: repeat(8, 1fr); /* Default for large screens */
    }
  }

  .active {
    background-color: #414bb2;
    color: white;
  }

  .campus-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    border: 1px #414bb2 solid;
    transition: 0.25s ease-in-out;
    font-size: 13px;
    font-weight: 600;
    padding: 5px;
    gap: 10px;

    img {
      width: 160px;
      height: 130px;
      object-fit: cover;
    }

    &.fade-in {
      transition: opacity 0.6s ease-out, transform 0.6s ease-out;
      transform: translateY(0);
    }
  }

  .program-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 13px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .btn-default, .btn-active {
    border-radius: 10px;
    font-weight: 700;
    font-size: 20px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .btn-default {
    background: #e6e6e6;
    color: black;
  }

  .btn-active {
    background: #414bb2;
    color: white;
  }

  /* Refined Media Queries */
  @media screen and (min-width: 1475px) and (max-width: 1710px) {
    .button-ctn {
      width: 100px;
      button {
        font-size: 16px;
      }
    }

    .campus-item img {
      width: 130px;
      height: 120px;
    }

    .campus-container .hs-container, .ms-container {
      grid-template-columns: repeat(8, 135px);
    }
  }

  @media screen and (min-width: 990px) and (max-width: 1280px) {
    .button-ctn {
      width: 80px;
      button {
        font-size: 14px;
      }
    }

    .campus-container .hs-container, .ms-container {
      grid-template-columns: repeat(6, 1fr); /* Medium screens */
    }

    .campus-item img {
      width: 120px;
      height: 100px;
    }
  }

  @media screen and (min-width: 780px) and (max-width: 990px) {
    // why i cant make this content go down
    .header-program {
      display: flex;
      background: white;
      z-index: 100;
      width: calc(100% - 60px);
      margin: 90px 0 0 !important;
      border: 1px red solid;

      .header-container {
        width: 80%;
        gap: 10px;
        display: flex;
        left: 0;
        right: 0;
        margin: 0 auto !important;
        justify-content: space-between;
        border: 1px red solid;

      }
      .btn-school {
        border: 1px red solid;
        margin-top: 0 !important;
      }
    }

    .button-ctn {
      width: 80px;
      button {
        font-size: 14px;
      }
    }

    .campus-container {
      .hs-container, .ms-container {
        grid-template-columns: repeat(5, 1fr);
      }
    }

    .campus-item img {
      width: 100px;
      height: 85px;
    }

    .btn-default, .btn-active {
      font-size: 15px;
    }
  }

  @media screen and (min-width: 540px) and (max-width: 780px) {
    .campus-container .hs-container, .ms-container {
      grid-template-columns: repeat(4, 1fr);
    }

    .campus-item img {
      width: 90px;
      height: 80px;
    }
  }

  @media screen and (max-width: 540px) {
    .campus-container .hs-container, .ms-container {
      grid-template-columns: repeat(2, 1fr);
    }

    .campus-item img {
      width: 100px;
      height: 80px;
    }
  }

  @media screen and (max-width: 360px) {
    .campus-container .hs-container, .ms-container {
      grid-template-columns: repeat(1, 1fr); /* Extra small screens */
    }

    .campus-item img {
      width: 80px;
      height: 60px;
    }
  }
}
