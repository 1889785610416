.card-container {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
  gap: 10px;
}

.card_body {
  border: 1px solid #014f7c;
  color: #014f7c;
  font-weight: 600;
  font-size: 15px;
  padding: 7px;
  border-radius: 8px;
  text-align: center;
  width: 80%;
  transition: 0.15s ease-in;
  margin: 0 auto;
}

.card-active {
  background-color: #014f7c;
  color: white;
}

.card_title {
  display: flex;
  justify-content: space-evenly;
}

.goback-btn {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #014f7c;
  font-size: 14px;
  padding: 12px;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 auto;
}

.icon {
  position: absolute;
  left: 5%;
}

.goback-btn:hover {
  opacity: 0.8;
}

@media (max-width: 360px) {
  .card_body {
    border: 1px solid #014f7c;
    color: #014f7c;
    font-weight: 600;
    font-size: 10px;
    padding: 7px;
    border-radius: 8px;
    text-align: center;
    transition: 0.15s ease-in;
    margin: 0 auto;
  }

  .card-active {
    background-color: #014f7c;
    color: white;
  }

  .goback-btn {
    font-size: 12px;
  }
}
