@media (max-width: 991px) {
  .col-md-12 {
    width: 100% !important;
  }
}
.congratulation-banner {
  background: #f7942a;
}

/* Hide the content behind the modal when the modal is open */
.modal-open .content-to-hide {
  display: none;  /* This hides the content behind the modal */
}

/* Optional: Keep the backdrop dimmed as usual */
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);  /* Dimmed background */
}