.dashboard-container {
    border: 1px solid lightsteelblue;
    border-radius: 5px;
    padding: 10px;
    margin: 20px auto 0;
    width: 95%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 15px;
    text-align: center;
  }
  
  .confirm-pay-button {
    position: absolute;
    right: 0;
    font-size: 20px;
    width: 150px;
  }