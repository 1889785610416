.logo {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  top: 20px;
  display: flex;
  height: 100px;
}
.register-background {
  // background-image: url('https://www.terraeducation.com/assets/images/bg-summer-springboard.png');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // color: white;
}