.header-container {
  position: fixed;
  z-index: 1000; 
  background: white; 
  padding: 1rem;
  width: calc(100% - 335px);
  top: 0;
}

.payment-btn {
  width: 180px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  border: 1px #414bb2 solid;
  font-size: 20px;
  background: #2d9bf0;
  color: white;
  
  &:hover {
    background: white;
    color: black;
  }

 &:disabled {
  pointer-events: none;
 }
}

.selected-all {
  position: absolute;
  right: 2%;
  background: #414bb2;
  color: white;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border: 1px #414bb2 solid;
  &:hover {
    background: white;
    color: black;
  }
}

.unselect {
  position: absolute;
  right: 2%;
  background: white;
  color: black;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border: 1px #414bb2 solid;
  &:hover {
    background: #414bb2;
    color: white;
  }
}